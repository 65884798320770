import { Controller } from '@hotwired/stimulus'

export default class EditableFormController extends Controller {
  static targets = ['showView', 'editView', 'form', 'selectedLabel', 'searchInput', 'checkbox']

  connect () {
    if (this.hasSelectedLabelTarget) {
      this.updateSelectedLabel()
    }
  }

  showEditable () {
    this.showViewTargets.forEach(el => {
      el.classList.add('hidden')
    })

    this.editViewTargets.forEach((el) => {
      el.classList.remove('hidden')
      if (this.hasSearchInputTarget) {
        el.dataset.hideInSearch = 'false'
      }
    })

    this.checkboxTargets.forEach(el => {
      el.disabled = false
    })
  }

  updateSelectedLabel () {
    const checkedCheckboxes = this.formTarget.querySelectorAll('input[type="checkbox"]:checked')
    const selectedValues = Array.from(checkedCheckboxes).map(cb => cb.value)
    if (this.hasSelectedLabelTarget) {
      this.selectedLabelTarget.textContent = selectedValues.length
    }
  }

  hideEditable () {
    this.editViewTargets.forEach(el => {
      el.classList.add('hidden')
      el.dataset.hideInSearch = 'true'
    })

    this.showViewTargets.forEach(el => el.classList.remove('hidden'))

    const controller = this

    // give time for the form to reset
    setTimeout(() => {
      const checkedCheckboxes = this.formTarget.querySelectorAll('input[type="checkbox"]:checked')
      const uncheckedCheckboxes = this.formTarget.querySelectorAll('input[type="checkbox"]:not(:checked)')
      checkedCheckboxes.forEach(el => {
        el.dataset.hideInSearch = 'false'
        el.classList.remove('hidden')
        controller.searchInputTarget.value = ''
      })

      uncheckedCheckboxes.forEach(el => {
        el.dataset.hideInSearch = 'true'
      })

      this.updateSelectedLabel()
    }, 200)
  }
}
